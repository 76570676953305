// components/Navbar.js

import React from 'react';
import logo from '../FS_Auth.png';
const Navbar = () => (

  <div id="skin_wrapper" style={{"zIndex": 50}}>
    <nav className="navbar navbar-dark navbar-expand-sm" style={{"backgroundColor": "#000", "marginLeft": "auto","marginRight": "auto"}}>
  <div className="container">
  <a className="navbar-brand" href="/">
    <img src={logo} className="d-inline-block align-top" alt="" width="37%"/>
  </a>
     <ul className="navbar-nav">
            <li className="nav-item">
                <a className="nav-link" href="https://www.roblox.com/groups/2803360/State-of-Firestone#!/about">
                  Group
                </a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="https://forums.stateoffirestone.com/">
                  Forums
                </a>
            </li>
        </ul>
  </div>
</nav>
</div>

);

export default Navbar;
