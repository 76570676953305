import React from "react";
import Markdown from 'react-markdown'
import terms from "../terms.md"



const Terms = () => {
    let [readable, setReadable] = React.useState({ md: "" });


React.useEffect(() => {
  fetch(terms)
    .then((res) => res.text())
    .then((md) => {
      setReadable({ md });
    });
}, []);
    return (
       <Markdown className={"container"} children={readable.md}></Markdown>
    );
};
 
export default Terms;