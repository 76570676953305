// components/Wizard.tsx
import React, { useState, useEffect, useRef } from 'react';

interface Step {
  title: string | JSX.Element;
  description: string;
  buttonText: string;
  buttonIcon: string;
  onClick: string;
}

const steps: Step[] = [
  {
    title: 'Step 1',
    description: 'Connect to Discord',
    buttonText: 'Login With Discord',
    buttonIcon: 'fab fa-discord',
    onClick: '/auth/discord',
  },
  {
    title: 'Step 2',
    description: 'Connect to Roblox',
    buttonText: 'Login With Roblox',
    buttonIcon: 'fa-solid fa-r',
    onClick: '/connect/roblox',
  },
  {
    title: 'Step 3',
    description: 'Accounts Linked!',
    buttonText: 'Sign out',
    buttonIcon: '',
    onClick: '/auth/logout',
  },
];

const Wizard: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [user, setUser] = useState<any>(null);
  const [isVertical, setIsVertical] = useState<boolean>(false);
  const liRef = useRef<HTMLLIElement[]>([]);

  const checkOrientation = (): void => {
    if (liRef.current[1] && liRef.current[0]) {
      setIsVertical(liRef.current[1].offsetTop > liRef.current[0].offsetTop);
    }
  };

  const fetchUser = async (): Promise<void> => {
    try {
      const response = await fetch('https://api.stateoffirestone.com/auth/login/success', {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      if (response.ok) {
        const userData = await response.json();
        setUser(userData.user);
      } else {
        setUser(null);
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      setUser(null);
    }
  };

  useEffect(() => {
    fetchUser();
    checkOrientation();
    const handleResize = (): void => checkOrientation();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    console.log(user)
    if (user) {
      setCurrentStep(user.roblox ? 2 : 1);
      if (user.roblox) {
        steps[2].title = (
          <>
                 <div className="profile-bubbles">
          <img src={`https://cdn.discordapp.com/avatars/${user?.id}/${user?.avatar}.png`} alt="Discord Avatar" className="profile-avatar discord-avatar" />
          <img src={user?.roblox?.picture} alt="Roblox Avatar" className="profile-avatar roblox-avatar" />
        </div>
      </>
        );
      }
    }
  }, [user]);

  const gradientDirection = isVertical ? 'to bottom' : 'to right';
  const gradientWidth = ((currentStep * 2) / steps.length) * 100;

  return (
    <div className="d-md-flex d-lg-flex d-xl-flex align-items-md-center align-items-lg-center align-items-xl-center wizard clearfix vertical">
      <div className="steps clearfix">
        <ul
          style={{
            backgroundImage: `linear-gradient(${gradientDirection}, #28a745  0%, #28a745  ${gradientWidth - 10}%, #a70101 ${gradientWidth - 10}%, #a70101 100%)`,
          }}
        >
          {steps.map((step, index) => (
            <li key={index} ref={(el) => (liRef.current[index] = el!)} className={index <= currentStep ? 'current' : ''}>
              <span className="number">{index + 1}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="d-lg-flex d-xl-flex content clearfix">
        <section>
          <h5 className="text-white-50">{steps[currentStep].title}</h5>
          <h2 className="text-white">{steps[currentStep].description}</h2>
          <button className="btn btn-primary" onClick={() => (window.location.href = `https://api.stateoffirestone.com${steps[currentStep].onClick}`)}>
            <i className={steps[currentStep].buttonIcon} style={{ paddingRight: '10px' }}></i>
            {steps[currentStep].buttonText}
          </button>
        </section>
      </div>
    </div>
  );
};

export default Wizard;
