// src/App.js
import Navbar from './components/Navbar.tsx';
import Wizard from './components/Wizard.tsx'
import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Terms from './components/Terms.tsx';
import Privacy
 from './components/Privacy.tsx';
import './styles/styles.min.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

function App() {





  return (
    <Router>
      <Navbar />
      <main className="container d-flex flex-column align-items-center">
        <div className="container d-inline-flex justify-content-center justify-content-lg-center m-auto">
        <Routes>
                <Route exact path="/" element={<Wizard />} />
                <Route path="/terms" element={<Terms />} />
                <Route
                    path="/privacy"
                    element={<Privacy />}
                />
                         <Route
                    path="*"
                    element={<Wizard />}
                />
                            </Routes>
        </div>
      </main>
      <footer className="bg-light3 text-center text-white">
        <div className="container">
  <div class="row justify-content-center py-2">
    <div class="col-6 col-sm-4 col-lg py-1"><small><a href="/terms">Terms</a></small></div>
    <div class="col-6 col-sm-4 col-lg py-1"><small><a href="/privacy">Privacy</a></small></div>
    
    
    <div class="col-6 col-sm-4 col-lg py-1"><small>Copyright © 2017–2024</small></div>
  </div>
        </div>
      </footer>
      </Router>
  )
}

export default App;
